
export default {
  name: 'AtomCategorySpecs',
  props: {
    categoryName: {
      required: true,
      type: [String, Object],
      default: ''
    },
    categoryId: {
      required: true,
      type: String,
      default: ''
    },
    activeCategory: {
      required: true,
      type: String,
      default: 'category-0'
    }
  },
  computed: {
    isActive() {
      return this.activeCategory === this.categoryId
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', this.categoryId)
    }
  }
}
