
export default {
  name: 'AtomPinSimpleText',
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          pinTitle: '',
          pinDescription: '',
          buttonLink: ''
        }
      }
    }
  }
}
