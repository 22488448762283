
export default {
  name: 'AtomPinImage',
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          pinTitle: '',
          pinDescription: '',
          imageSrc: ''
        }
      }
    }
  },
  data() {
    return {
      isInView: false
    }
  },
  computed: {
    resourceUrl() {
      if (!this.isInView) return ''
      if (this.pinInfo.imageSrc.includes('://')) {
        return this.pinInfo.imageSrc
      }
      return `${this.$store.getters.cdnBase}/${this.pinInfo.imageSrc}`
    }
  },
  mounted() {
    this.isInView = true
  },
  methods: {
    handleLazyLoad() {
      this.isInView = true
    }
  }
}
