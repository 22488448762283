
export default {
  name: 'AtomPinVideo',
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function () {
        return {
          pinTitle: '',
          pinDescription: '',
          videoSrc: ''
        }
      }
    }
  },
  data() {
    return {
      isInView: false
    }
  },
  computed: {
    isHostedVideo() {
      return !(!this.resourceUrl.includes('youtube') && !this.resourceUrl.includes('vimeo'))
    },
    resourceUrl() {
      if (!this.isInView) return ''
      if (this.pinInfo.videoSrc.includes('://')) {
        if (this.pinInfo.videoSrc.includes('youtube')) {
          return this.pinInfo.videoSrc + '?enablejsapi=1' // we need to add this to be able to stop/pause a youtube video programmatically.
        }
        return this.pinInfo.videoSrc
      }
      return `${this.$store.getters.cdnBase}/${this.pinInfo.videoSrc}`
    }
  },
  mounted() {
    this.isInView = true
  },
  methods: {
    handleLazyLoad() {
      this.isInView = true
    }
  }
}
