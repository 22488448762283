
const TransportationTypes = {
  DISPLAY_ALL: 'display-all',
  DRIVING: 'driving',
  WALKING: 'walking',
}

export default {
  name: 'AtomLocationType',
  props: {
    category: {
      required: false,
      type: String,
      default: ''
    },
    categoryLabel: {
      required: false,
      type: [String, Object],
      default: ''
    },
    totalNumber: {
      required: false,
      type: Number,
      default: 0
    },
    defaultMode: {
      required: false,
      type: String,
      default: 'walking'
    },
    useDisplayAllTab: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      filters: {},
      TransportationTypes
    }
  },
  methods: {
    toggleSection() {
      this.filters = {
        selectedCategory: this.$el
          .querySelectorAll('.change-info-boxes')[0]
          .getAttribute('data-category'),
        transportationType: this.$el
          .querySelector('.common-tab.active')
          .getAttribute('data-transportation')
      }
      this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters)

      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem
        })
      }
      const target = this.$el
      target.classList.toggle('active')

      this.$emit('category-toggle', target)
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('active')
      })
    },
    selectSliderOption(e, addActiveClassSelector = TransportationTypes.WALKING, removeActiveClassSelectors = []) {
      e.stopPropagation()
      if (this.$el.querySelector(`.${addActiveClassSelector}-tab`)?.classList?.contains('active')) return;

      this.$emit('category-toggle', this.$el)
      this.$el.querySelector(`.${addActiveClassSelector}-tab`)?.classList?.add('active')
      this.filters.transportationType = addActiveClassSelector
      this.$emit('filters', this.filters)
      this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters);

      if(!removeActiveClassSelectors.length) return;
      for (const rmv of removeActiveClassSelectors) {
        this.$el.querySelector(`.${rmv}-tab`)?.classList?.remove('active')
      }
    },
  }
}
